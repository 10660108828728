"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loading = exports.sort = exports.header = exports.flexCenter = exports.products = exports.root = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _media = require("../../../constants/media");

var root = {
  marginBottom: '50px'
};
exports.root = root;

var products = function products(background) {
  return (0, _defineProperty2["default"])({
    textAlign: 'center',
    boxSizing: 'border-box',
    borderRadius: '1px',
    padding: '30px 30px 0',
    letterSpacing: '0.7px',
    color: '#666',
    minHeight: '190px',
    background: background
  }, _media.PHONE_MEDIA, {
    padding: '20px 10px 0',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  });
};

exports.products = products;
var flexCenter = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};
exports.flexCenter = flexCenter;
var header = {
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  fontSize: '18px',
  marginBottom: '20px',
  paddingLeft: '15px',
  minHeight: '27px'
};
exports.header = header;
var sort = {
  cursor: 'pointer',
  width: '25px'
};
exports.sort = sort;

var loading = function loading(colors) {
  return {
    fontSize: 60,
    color: colors[2]
  };
};

exports.loading = loading;