"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getProductsInActivity = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _client = require("@apollo/client");

var _locale = require("@meepshop/utils/lib/gqls/locale");

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  query getProductsInActivity($search: searchInputObjectType) {\n    computeProductList(search: $search) {\n      data {\n        id\n        title {\n          ...localeFragment\n        }\n        coverImage {\n          id\n          scaledSrc {\n            w60\n            w120\n            w240\n            w480\n            w720\n            w960\n            w1200\n            w1440\n            w1680\n            w1920\n          }\n        }\n        variants {\n          id\n          totalPrice\n        }\n      }\n      total\n    }\n  }\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var getProductsInActivity = (0, _client.gql)(_templateObject(), _locale.localeFragment);
exports.getProductsInActivity = getProductsInActivity;