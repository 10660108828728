"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.root = void 0;
var root = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '20px',
  fontSize: '14px'
};
exports.root = root;