"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getActivity = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _client = require("@apollo/client");

var _locale = require("@meepshop/utils/lib/gqls/locale");

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  query getActivity($input: StoreActivityFilterInput) {\n    viewer {\n      id\n      store {\n        id\n        activity(input: $input) {\n          id\n          target {\n            groups {\n              id\n              title {\n                ...localeFragment\n              }\n              tags\n              products {\n                productId\n              }\n              method\n              value\n              operator\n              params {\n                tags\n                price {\n                  gte\n                  lte\n                }\n                search\n                includedAllTags\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var getActivity = (0, _client.gql)(_templateObject(), _locale.localeFragment);
exports.getActivity = getActivity;