"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.blank = exports.root = void 0;
var root = {
  width: '100%',
  fontSize: '16px',
  color: '#666666'
};
exports.root = root;
var blank = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '200px',
  color: '#999999',
  fontSize: '18px',
  fontWeight: 500,
  background: '#F5F5F5'
};
exports.blank = blank;